import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import guideKlassisk from "../images/guide_klassisk.min.png"
import guideNybyg from "../images/guide_nybyg.min.png"
import guideFoldegardin from "../images/guide_foldegardin.min.png"
import guideVaeg from "../images/guide_vaeg_til_vaeg.min.png"
import { linkResolver } from "../prismic/linkResolver"
import { defaultLang } from "../../prismic-configuration"
import getTranslations from "../translations"

const query = graphql`
  query {
    prismic {
      allPages(uid: "guidetilopmaling") {
        edges {
          node {
            _meta {
              lang
            }
            title
            body {
              ... on PRISMIC_PageBodyFeature {
                label
                primary {
                  feature_title
                  feature_text
                  feature_image
                  feature_heading
                  feature_imageSharp {
                    childImageSharp {
                      sizes {
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Guide = props => {
  const { lang } = props
  const t = getTranslations(lang)
  const [showResults, setShowResults] = React.useState(false)
  const [chosenImage, setChosenImage] = React.useState()
  const [chosenTitle, setChosenTitle] = React.useState()
  const [chosenDescription, setChosenDescription] = React.useState()
  function handleClick(src, title) {
    setShowResults(true)
    setChosenTitle(title)
    setChosenImage("")
    setTimeout(
      function() {
        setChosenImage(src)
      }.bind(this),
      300
    )
  }

  const classicLabel = t.guide.classic
  const newBuildLabel = t.guide.new_build
  const wallToWallLabel = t.guide.wall_to_wall
  const foldingLabel = t.guide.folding

  const backClick = () => {
    setShowResults(false)
  }

  const descriptionNybyg = "<strong>Trin 1:</strong> Mål bredden af åbningen "

  return (
    <div>
      <div>
        {!showResults && (
          <h3 className="fs--m mb--s lh--s">
            {t.guide.subtitle}
          </h3>
        )}

        <StaticQuery
          query={query}
          render={data => {
            let guides = data.prismic.allPages.edges.find(page => page.node._meta.lang === lang);
            guides = guides.node.body

            return (
              <>
                {showResults && (
                  <div className="measuring-guide-chosen-window">
                    <h3 className="fs--m mb--s lh--s">
                      {chosenTitle}&nbsp;&nbsp;&nbsp;
                      <span className="fs--xs co--light lh--s mb--xs">
                        <a onClick={backClick}>({t.guide.go_back})</a>
                      </span>
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: chosenDescription }}
                    ></div>
                    {chosenTitle == classicLabel && (
                      <div className="co--light lh--m mb--s">
                        {RichText.render(
                          guides[1].primary.feature_text,
                          linkResolver
                        )}
                        <img src={guides[1].primary.feature_image.url} />
                      </div>
                    )}
                    {chosenTitle == newBuildLabel && (
                      <div className="co--light lh--m mb--s">
                        {RichText.render(
                          guides[2].primary.feature_text,
                          linkResolver
                        )}
                        <img src={guides[2].primary.feature_image.url} />
                      </div>
                    )}

                    {chosenTitle == wallToWallLabel && (
                      <div className="co--light lh--m mb--s">
                        {RichText.render(
                          guides[3].primary.feature_text,
                          linkResolver
                        )}
                        <img src={guides[3].primary.feature_image.url} />
                      </div>
                    )}
                    {chosenTitle == foldingLabel && (
                      <div className="co--light lh--m mb--s">
                        {RichText.render(
                          guides[4].primary.feature_text,
                          linkResolver
                        )}
                        <img src={guides[4].primary.feature_image.url} />
                      </div>
                    )}
                  </div>
                )}
              </>
            )
          }}
        />
      </div>

      {!showResults && (
        <div className="measuring-guide-window">
          <img
            alt={classicLabel}
            src={guideKlassisk}
            onClick={e => handleClick(e.target.src, e.target.alt)}
          />
          <div>{classicLabel}</div>
        </div>
      )}

      {!showResults && (
        <div className="measuring-guide-window">
          <img
            alt={newBuildLabel}
            src={guideNybyg}
            onClick={e => handleClick(e.target.src, e.target.alt)}
          />
          <div>{newBuildLabel}</div>
        </div>
      )}

      {!showResults && (
        <div className="measuring-guide-window">
          <img
            alt={wallToWallLabel}
            src={guideVaeg}
            onClick={e => handleClick(e.target.src, e.target.alt)}
          />
          <div>{wallToWallLabel}</div>
        </div>
      )}

      {!showResults && (
        <div className="measuring-guide-window">
          <img
            alt={foldingLabel}
            src={guideFoldegardin}
            onClick={e => handleClick(e.target.src, e.target.alt)}
          />
          <div>{foldingLabel}</div>
        </div>
      )}
    </div>
  )
}

export default Guide
