import React from "react"

const Guidance = ({ guidance, instagramImages }) => {
  let greeting
  let collection = guidance.fields["Collection"]
  let colorScale = guidance.fields["Color scale"]
  let curtainLook = guidance.fields["Curtain look"]
  let railLook = guidance.fields["Rail look"]
  let customText = guidance.fields["Custom text"]
  let igLink1 = guidance.fields["IG link 1"]
  let igLink2 = guidance.fields["IG link 2"]
  let igLink3 = guidance.fields["IG link 3"]

  const GREETING_MAP = {
    "Hi 1": "Hej med dig",
  }

  const COLLECTION_MAP = {
    Velvet:
      "Bløde, tidsløse velourgardiner skaber en underspillet luksuriøs og hyggelig hotelstemning. Findes i en bred palette af farverige, gyldne og støvede nuancer, der komplimenterer enhver indretning og vægfarve. Brug Velvet-gardinerne som smukke rumdelere, forhæng og ved vinduerne for et smukt varmt udtryk.",
  }

  const COLOR_SCALE_MAP = {
    Red: "Rød er farven",
  }

  const CURTAIN_LOOK_MAP = {
    Slæbende: "Slæbende er flot",
    Svævende: "Svævende er flot",
  }

  const RAIL_LOOK_MAP = {
    Loftskinne: "Slæbende er flot",
    Vægskinne: "Svævende er flot",
  }

  if (!guidance.fields) {
    return null
  }

  console.log("customText", customText)

  return (
    <div>
      <h2 className="fs--m" style={{ fontSize: "25px", fontWeight: 300 }}>
        Din stylerådgivning
      </h2>
      <div className="card mb--m" style={{ border: "1px solid #555" }}>
        {customText && <div>{customText}</div>}

        <div style={{ fontWeight: "bold", marginTop: 15 }}>
          Kollektion - {collection}
        </div>
        <div>{COLLECTION_MAP[collection]}</div>

        {/* <div style={{ fontWeight: "bold", marginTop: 15 }}>
          Farveskala - {colorScale}
        </div>
        <div>{COLOR_SCALE_MAP[colorScale]}</div> */}

        <div style={{ fontWeight: "bold", marginTop: 15 }}>Look</div>
        <div>
          {COLOR_SCALE_MAP[colorScale]}. {CURTAIN_LOOK_MAP[curtainLook]}{" "}
          gardiner og {railLook}
        </div>

        {!customText && <div></div>}
        <div style={{ marginTop: 15 }}>
          <div style={{ fontWeight: "bold" }}>Eksempler på stylinger</div>
          {instagramImages &&
            instagramImages.map((image, idx) => {
              return (
                <div id={idx}>
                  {image.fields["Link 1"] && (
                    <div>
                      <a href={image.fields["Link 1"]} target="_blank">
                        {image.fields["Link 1"]}
                      </a>
                    </div>
                  )}
                  {image.fields["Link 2"] && (
                    <div>
                      <a href={image.fields["Link 2"]} target="_blank">
                        {image.fields["Link 2"]}
                      </a>
                    </div>
                  )}
                  {image.fields["Link 3"] && (
                    <div>
                      <a href={image.fields["Link 3"]} target="_blank">
                        {image.fields["Link 3"]}
                      </a>
                    </div>
                  )}
                </div>
              )
            })}

          {igLink1 && (
            <div>
              <a href={igLink1} target="_blank">
                {igLink1}
              </a>
            </div>
          )}

          {igLink2 && (
            <div>
              <a href={igLink2} target="_blank">
                {igLink2}
              </a>
            </div>
          )}

          {igLink3 && (
            <div>
              <a href={igLink3} target="_blank">
                {igLink3}
              </a>
            </div>
          )}
        </div>
        <div style={{ marginTop: 15 }}>
          <div style={{ fontWeight: "bold" }}>Stofprøver </div>
          <div>
            Bestil stofprøver her:{" "}
            <a href="https://www.anddrape.com/stofproever/">
              https://www.anddrape.com/stofproever/
            </a>
          </div>
        </div>
        <div style={{ marginTop: 15 }}>
          Du kan også beregne din pris og evt. bestille online ud fra vores
          stylingsnak - skriv hvis specialmål du ikke kan løse online eller
          nogle spørgsmå og vi hjælper. Vi glæder os til at hjælpe dig videre
          med smukke gardiner. Se mere{" "}
          <a href="https://www.anddrape.com/shop/">
            https://www.anddrape.com/shop/
          </a>
        </div>
      </div>
    </div>
  )
}

export default Guidance
