import React, { useContext, useState, useEffect } from "react"
import Spinner from "react-spinner-material"
import StoreContext from "../context/StoreContext"
import ProjectWindow from "../components/Windows/Index"
import GuideComponent from "../components/Guide"
import Guidance from "../components/Guidance/"
import formatPrice from "../utils/formatPrice"
import NewWindow from "../components/Windows/NewWindow"

const Project = () => {
  const context = useContext(StoreContext)
  const [refetch, setRefetch] = useState(0)
  const [project, setProject] = useState()
  const [isLoading, setLoading] = useState(true)
  const [isDisabled, setDisabled] = useState(false)
  const [price, setPrice] = useState(0)
  const [installationPrice, setInstallationPrice] = useState(0)
  const [shippingPrice, setShippingPrice] = useState(0)
  const [excludeVAT, setExcludeVAT] = useState(false)
  const [estimate, setEstimate] = useState(false)
  const [count, setCount] = useState(0)

  let projectId
  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1))
    projectId = params.get("id") // is the string "Jonathan"
  }

  const { setModalVisibility, setModalContent } = context

  const handleClick = (title, text) => {
    setModalVisibility(true)
    setModalContent(title, text, "image")
  }

  useEffect(() => {
    async function fetchProject() {
      const response = await fetch(
        "/.netlify/functions/fetch-project?id=" + projectId,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      let project = await response.json()
      setLoading(false)
      setProject(project)
    }

    fetchProject()
  }, [refetch, count])

  useEffect(() => {
    if (!project) {
      return
    }

    const disabled =
      project.project.fields.Status === "Offer sent" ||
      project.project.fields.Status === "Unfulfilled" ||
      project.project.fields.Status === "Fulfilled"
        ? true
        : false

    setDisabled(disabled)

    let excludeVAT = project.project.fields["Show price ex VAT"] ? true : false

    let price = excludeVAT
      ? (parseInt(project.project.fields["Offer price"]) / 100) * 80
      : project.project.fields["Offer price"]

    let installationPrice = excludeVAT
      ? (parseInt(project.project.fields["Custom installation price"]) / 100) *
        80
      : project.project.fields["Custom installation price"]

    let shippingPrice = excludeVAT
      ? (parseInt(project.project.fields["Custom shipping price"]) / 100) * 80
      : project.project.fields["Custom shipping price"]

    setExcludeVAT(excludeVAT)
    setPrice(price)
    setInstallationPrice(installationPrice)
    setShippingPrice(shippingPrice)
    setEstimate(!!project.project.fields["Estimate"])
  }, [project])

  return (
    <>
      <div
        className="measuring-guide-container co--light lh--m mb--l article project"
        style={{ paddingBottom: "80px" }}
      >
        <h1 style={{ marginBottom: 10 }}>Dit projekt</h1>
        <div style={{ marginBottom: 30 }}>
          Skab stemning med gardiner syet efter mål
        </div>
        {isLoading && (
          <div>
            <Spinner radius={20} color={"#333"} stroke={1} visible={true} />
          </div>
        )}
        {!isLoading && (
          <>
            <div className="order-price">
              <div style={{ fontWeight: "bold", marginTop: 20 }}>
                {project && price > 0 && (
                  <>
                    Samlet pris: {price > 0 && formatPrice(price)}{" "}
                    {excludeVAT ? "ex. moms" : "inkl. moms"}
                  </>
                )}
              </div>
            </div>

            {shippingPrice > 0 && <>Levering: {formatPrice(shippingPrice)}</>}
            {shippingPrice === 0 && <>Gratis levering</>}

            {installationPrice > 0 && (
              <>Montering: {formatPrice(installationPrice)}</>
            )}

            {installationPrice === 0 && <>Gratis montering</>}

            {project &&
              project.project.fields["Inform about installation price"] > 0 && (
                <div style={{ fontSize: 16 }}>
                  Montering vil koste{" "}
                  {formatPrice(
                    project.project.fields["Inform about installation price"]
                  )}{" "}
                  Tilvælges i checkout.
                </div>
              )}
          </>
        )}
        {project &&
          project.project.fields["Measurement status"] ===
            "Measurement payment requested" &&
          project.measurementDraftOrder && (
            <div style={{ marginTop: 20 }}>
              <a
                href={project.measurementDraftOrder.invoice_url}
                target="_blank"
              >
                <div className="button fs--s consultancy-button primary">
                  <div className="button-text">Bestil opmåling</div>
                  <div className="button-icon">
                    <svg className="stroke" viewBox="0 0 24 24">
                      <path d="M9 18l6-6-6-6"></path>
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          )}
        {project && (
          <>
            {project.draftOrder && (
              <div style={{ marginTop: 20 }}>
                <a href={project.draftOrder.invoice_url} target="_blank">
                  <div className="button fs--s consultancy-button primary">
                    <div className="button-text">Gå til betaling</div>
                    <div className="button-icon">
                      <svg className="stroke" viewBox="0 0 24 24">
                        <path d="M9 18l6-6-6-6"></path>
                      </svg>
                    </div>
                  </div>
                </a>
                <div style={{ fontSize: "14px" }}>
                  Du bekræfter medsendte tilbud ved bestilling. Leveringstiden
                  er ca. 21-28 hverdage fra orden er lagt. (NB! Nogle farver er
                  meget populære og sælges hurtigt, hvis evt. udsolgt farve og
                  derved lidt ekstra leveringstid, kontakter vi dig, når ordren
                  er lagt og bekræfter leveringsdatoen).
                </div>
              </div>
            )}
          </>
        )}
        <hr style={{ margin: "35px 0 35px" }} />
        {estimate && (
          <div
            style={{
              background: "#FFEFCA",
              border: "1px solid #ddd",
              padding: 15,
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            Obs. Følgende er et prisestimat. Endelige pris beregnes, når vi har
            de endelige mål.
          </div>
        )}
        {project && project.guidance && (
          <Guidance
            guidance={project.guidance}
            instagramImages={project.instagramImages}
          />
        )}
        {project && !isDisabled && (
          <div>
            <div
              className="mb--m how-to-measure"
              style={{ float: "right", marginTop: 5 }}
            >
              <a
                onClick={() => {
                  handleClick(
                    "Sådan måler du højde og bredde",
                    <GuideComponent />
                  )
                }}
              >
                Sådan måler du højde og bredde
              </a>
            </div>
            <h2 className="fs--m" style={{ fontSize: "25px", fontWeight: 300 }}>
              Gardiner
            </h2>
          </div>
        )}
        <NewWindow setCount={setCount} count={count} projectId={projectId} />
        {project &&
          project.windows.records.map((window, index) => {
            return (
              <ProjectWindow
                window={window}
                key={window.id}
                index={index}
                internal={false}
                excludeVAT={excludeVAT}
                status={project.project.fields.Status}
                projectId={projectId}
                refetch={refetch}
                setRefetch={setRefetch}
              />
            )
          })}
      </div>
    </>
  )
}

export default Project
